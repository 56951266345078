import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { Button, Column, Layout, Player, Row, Text } from '../components'
import { TEXTS } from '../constants'
import galleryIcon from '../images/icons/gallery.svg'

import styles from './start.module.css'

export default () => {
    const { text, appeal, soldiers, mother, molotov, muscovites, europe } = useStaticQuery(graphql`
      {
        text: contentfulText(tid: {eq: "start-of-war"}) {
          title
          content {
            json
          }
        },
        appeal: contentfulSong(title: {eq: "Выступление по радио заместителя Председателя Народных Комиссаров Союза ССР и Народного Комиссара Иностранных Дел В.М. Молотова 22 июня 1941 года"}) {
          title
          fileMp3 {
            localFile {
              publicURL
            }
          }
          fileOgg {
            localFile {
              publicURL
            }
          }
        },
        soldiers: contentfulAsset(title: {eq: "Солдаты"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        mother: contentfulAsset(title: {eq: "Родина-Мать"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        molotov: contentfulAsset(title: {eq: "Молотов"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        muscovites: contentfulAsset(title: {eq: "Москвичи"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        europe: contentfulAsset(title: {eq: "Европа в 1941 году"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
      }`)
    return (
        <Layout pageTitle={TEXTS.startOfWar}>
            <Row>
                <Column width={50}>
                    <h1 className={styles.header}>{appeal.title}</h1>
                    <div className={styles.player}>
                        <Player ogg={appeal.fileOgg.localFile.publicURL} mp3={appeal.fileMp3.localFile.publicURL} inverter/>
                    </div>
                    <Text content={text.content.json}/>
                    <Img fluid={molotov.localFile.childImageSharp.fluid} alt={TEXTS.molotov} className={styles.photo}/>
                    <Img fluid={soldiers.localFile.childImageSharp.fluid} alt={TEXTS.soldiers} className={styles.photo}/>
                    <a href="https://www.kommersant.ru/doc/4074777" target="_blank" title={TEXTS.europe}>
                        <Img fluid={europe.localFile.childImageSharp.fluid} alt={TEXTS.europe}/>
                    </a>
                </Column>
                <Column width={50}>
                    <Img fluid={muscovites.localFile.childImageSharp.fluid} alt={TEXTS.muscovites}/>
                    <Button text={TEXTS.appealVideo} icon={galleryIcon} external to="https://vk.com/videos-39427366?z=video-39427366_456239056%2Fclub39427366%2Fpl_-39427366_-2"/>
                    <Button text={TEXTS.sacredWar} external to="https://vk.com/videos-39427366?z=video-39427366_456239057%2Fclub39427366%2Fpl_-39427366_-2"/>
                    <Img fluid={mother.localFile.childImageSharp.fluid} alt={TEXTS.mother}/>
                </Column>
            </Row>
        </Layout>
    )
}
